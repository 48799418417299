import { HotdeskBookingStatuses } from "../../enums/HotdeskBookings/HotdeskBookingStatuses";
import General from "../../resources/AdminUI/General";
import HotdeskBookingsGrid from "../../resources/AdminUI/HotdeskBookings/HotdeskBookingsGrid";
import { BaseEntity } from "../../utils/reactUtils";
import { String_Format } from "../../utils/utils";
import { IHotdeskBookingsItem } from "./Interfaces/IHotdeskBookingsItem";
import { formatDate, formatDateInZone } from "../../utils/date";

export class HotdeskBookingsItem extends BaseEntity implements IHotdeskBookingsItem {

    constructor(e: IHotdeskBookingsItem) {
        super();
        this.additionalConstructor(this, e);

        this.ForProperty(p => "id")
            .SetDisplayName(HotdeskBookingsGrid.Resources.visitId)
            .Show(_self => <div>
                {
                  e.bookingGroupId && 
                  <div className="id_group_booking">
                    ID GROUP: #{e.bookingGroupId}
                  </div>
                }
                <p>{String_Format(HotdeskBookingsGrid.Resources.id, this.id)}</p>
            </div>);

        this.ForProperty(p => "isWalkin")
            .SetDisplayName(HotdeskBookingsGrid.Resources.spaceType)
            .Show(_self => <div>
                <p>{this.isWalkin ? HotdeskBookingsGrid.Resources.walkin : HotdeskBookingsGrid.Resources.bookingRequired}</p>
            </div>);

        this.ForProperty(p => "workspaceId")
            .SetDisplayName(HotdeskBookingsGrid.Resources.space)
            .Show(_self => <div>
                <p className="room-name">{this.workspaceName}</p>
                <p>{String_Format(HotdeskBookingsGrid.Resources.spaceId, this.workspaceId)}</p>
            </div>);

        this.ForProperty(p => "userId")
            .SetDisplayName(HotdeskBookingsGrid.Resources.userInfo)
            .Show(_self => <div className="column-large">
                <div>{this.userFirstName} {this.userLastName}</div>
                <p>{String_Format(HotdeskBookingsGrid.Resources.userId, this.userId)}</p>
            </div>);

        this.ForProperty(p => "statusId")
            .SetDisplayName(HotdeskBookingsGrid.Resources.status)
            .Show(_self => {
                switch (this.statusId) {
                    case HotdeskBookingStatuses.Ended:
                        return <div className="column-large">
                            <p>
                                {this.isFullDay.valueOf()
                                    ? String_Format(HotdeskBookingsGrid.Resources.statusFullDay, this.statusName)
                                    : String_Format(HotdeskBookingsGrid.Resources.statusHalfDay, this.statusName)}
                                {General.Resources.at}
                                <span className="timestamp">
                                    {formatDateInZone(this.checkoutAt, this.timezone)}
                                    <span className="timezone">{this.timezone}</span>
                                </span>
                            </p>
                        </div>
                    case HotdeskBookingStatuses.Cancelled:
                        return <div className="column-large">
                            <p>
                                {this.statusName} {General.Resources.at}
                                <span className="timestamp"> {formatDate(this.cancelledAt)}</span>
                            </p>
                        </div>
                    case HotdeskBookingStatuses.Unredemeed:
                        return <div className="column-large">
                            <p>
                                {this.statusName} {General.Resources.at}
                                <span className="timestamp"> {formatDate(this.expiresAt)}</span>
                            </p>
                        </div>
                    case HotdeskBookingStatuses.Scheduled:
                        return <div className="column-large">
                            <p>
                                {this.statusName} {General.Resources.at}
                                <span className="timestamp">
                                    {formatDateInZone(this.bookingAt, this.timezone)}
                                    <span className="timezone">{this.timezone}</span>
                                </span>
                            </p>
                        </div>
                    case HotdeskBookingStatuses.Checkedin:
                        return <div className="column-large">
                            <p>
                                {this.statusName} {General.Resources.at}
                                <span className="timestamp">
                                    {formatDateInZone(this.checkinAt, this.timezone)}
                                    <span className="timezone">{this.timezone}</span>
                                </span>
                            </p>
                        </div>
                }
            });

        this.ForProperty(p => "createdAt")
            .Show(_self => {
                switch (this.statusId) {
                    case HotdeskBookingStatuses.Ended:
                        return <div>
                            <div className="span-details-row">
                                {HotdeskBookingsGrid.Resources.bookingCreatedAt}
                                <span className="timestamp">{formatDate(this.createdAt)}</span>
                            </div>
                            <div className="span-details-row">
                                {HotdeskBookingsGrid.Resources.bookingFor}
                                <span className="timestamp">
                                    {formatDateInZone(this.bookingAt, this.timezone)}
                                    <span className="timezone">{this.timezone}</span>
                                </span>
                            </div>
                            <div className="span-details-row">
                                {HotdeskBookingsGrid.Resources.checkinAt}
                                <span className="timestamp">
                                    {formatDateInZone(this.checkinAt, this.timezone)}
                                    <span className="timezone">{this.timezone}</span>
                                </span>
                            </div>
                            <div className="span-details-row">{HotdeskBookingsGrid.Resources.checkoutAt}
                                {this.isAutomatedCheckout ?
                                    <span>
                                        {String_Format(HotdeskBookingsGrid.Resources.autoCheckout, formatDateInZone(this.checkoutAt, this.timezone), this.timezone)}
                                    </span>
                                    :
                                    <span className="timestamp">
                                        {formatDateInZone(this.checkoutAt, this.timezone)}
                                        <span className="timezone">{this.timezone}</span>
                                    </span>
                                }
                            </div>
                            <div className="span-details-row last">{HotdeskBookingsGrid.Resources.timeSpent}
                                <span>
                                    {this.isFullDay.valueOf() ?
                                        String_Format(HotdeskBookingsGrid.Resources.timeSpentFullDay, Math.floor(parseFloat(this.timeSpent)), Math.floor((parseFloat(this.timeSpent) - Math.floor(parseFloat(this.timeSpent))) * 60))
                                        :
                                        String_Format(HotdeskBookingsGrid.Resources.timeSpentHalfDay, Math.floor(parseFloat(this.timeSpent)), Math.floor((parseFloat(this.timeSpent) - Math.floor(parseFloat(this.timeSpent))) * 60))
                                    }
                                </span>
                            </div>
                        </div >
                    case HotdeskBookingStatuses.Cancelled:
                        return <div>
                            <div className="span-details-row">
                                {HotdeskBookingsGrid.Resources.bookingCreatedAt}
                                <span className="timestamp">{formatDate(this.createdAt)}</span>
                            </div>
                            <div className="span-details-row">
                                {HotdeskBookingsGrid.Resources.bookingFor}
                                <span className="timestamp">{formatDateInZone(this.bookingAt, this.timezone)} <span className="timezone">{this.timezone}</span></span>
                            </div>
                            <div className="span-details-row last">
                                {HotdeskBookingsGrid.Resources.cancelledAt}
                                <span className="timestamp">{formatDate(this.cancelledAt)}</span>
                            </div>
                        </div>
                    case HotdeskBookingStatuses.Unredemeed:
                        return <div>
                            <div className="span-details-row">
                                {HotdeskBookingsGrid.Resources.bookingCreatedAt}
                                <span className="timestamp">{formatDate(this.createdAt)}</span>
                            </div>
                            <div className="span-details-row">
                                {HotdeskBookingsGrid.Resources.bookingFor}
                                <span className="timestamp">{formatDateInZone(this.bookingAt, this.timezone)} <span className="timezone">{this.timezone}</span></span>
                            </div>
                            <div className="span-details-row last">
                                {HotdeskBookingsGrid.Resources.unredemeedAt}
                                <span className="timestamp">{formatDate(this.expiresAt)} - {HotdeskBookingsGrid.Resources.userDidntCancelSoBookingExpiredAndWasUnredeemed}</span>
                            </div>
                        </div>
                    case HotdeskBookingStatuses.Scheduled:
                    case HotdeskBookingStatuses.Checkedin:
                    default:
                        return <div>
                            <div className="span-details-row">
                                {HotdeskBookingsGrid.Resources.bookingCreatedAt}
                                <span className="timestamp">{formatDate(this.createdAt)}</span>
                            </div>
                            <div className="span-details-row last">
                                {HotdeskBookingsGrid.Resources.bookingFor}
                                <span className="timestamp">{formatDateInZone(this.bookingAt, this.timezone)} <span className="timezone">{this.timezone}</span></span>
                            </div>
                        </div>
                }
            });
    }

    id: number;
    createdAt: string;
    unixBookingAtWithTimezone: number;
    bookingAt: string;
    checkinAt: string;
    checkoutAt: string;
    cancelledAt: string;
    expiresAt: string;
    timeSpent: string;
    timezone: string;
    workspaceId: number;
    workspaceName: string;
    isFullDay?: boolean;
    isWalkin: boolean;
    isAutomatedCheckout?: boolean;
    userId: number;
    userFirstName: string;
    userLastName: string;
    statusId: number;
    statusName: string;
}